import { ListPayment } from "../PaymentMethods/ListPayment/ListPayment";
import aiAssistant from "../../../public/main/Rastcom-AI.png";
import { useTranslation } from "react-i18next";


export default function Ai() {
    const { t } = useTranslation();

    return (
        <ListPayment
            header={t('aiAssistant.header')}
            description={t('aiAssistant.description')}
            img={aiAssistant}
        />
    )
}