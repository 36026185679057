import {Box, Divider, useMediaQuery, useTheme} from '@mui/material';
import React from 'react';
import Typography from "@mui/material/Typography";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import StarRateIcon from "@mui/icons-material/StarRate";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import {useTranslation} from "react-i18next";
import { motion } from 'framer-motion'
// import Luna from "../../../public/main/luna3.png"

export const ComparisonHome = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const blockItemStyleLeft = {
        display: "flex",
        width: "100%",
        alignItems: "center",
        backgroundColor: "#f6f6f6",
        borderTop:'1px solid grey',
        borderLeft:'1px solid grey',
        borderRadius: "10px",
        padding: "0px 10px",
        transition: "0.5s",
        // margin: "5px 0",
        ":hover": {
            backgroundColor: '#e6e6e6',
            cursor: 'pointer'
        },
        boxShadow: '0px 0px 20px 8px rgba(22, 163, 74, 0.2)'
    }
    const blockItemStyleRight = {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "flex-end",
        backgroundColor: "#f6f6f6",
        borderTop:'1px solid grey',
        borderRight:'1px solid grey',
        borderRadius: "10px",
        padding: "0px 10px",
        transition: "0.5s",
        // margin: "5px 5px",
        ":hover": {
            backgroundColor: '#e6e6e6',
            cursor: 'pointer'
        },
        boxShadow: '0px 0px 20px 8px rgba(22, 163, 74, 0.2)'
    }
    return (
        <Box 
                sx={{
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                }}
        >
            <Divider sx={{color:'#FF7600'}} role="presentation">
            {/* <motion.div
                        initial={{
                            scale:0
                        }}
                        whileInView={{
                            scale:1
                        }}
                        transition={{
                            duration:.5
                        }}
                    > */}
            <Typography fontSize={30} fontWeight="600" align="center" variant="h3"
                        sx={{
                            margin: "20px 0", 
                            padding: '10px', 
                            color: '#32325d',
                            backgroundColor: "#F9FAFB",
                            borderRadius: '30px',
                            boxShadow: "0px 1px 1px rgba(192, 192, 192, 0.6), 0px 1px 1px rgba(192, 192, 192, 0.6), 0px 1px 1px rgba(192, 192, 192, 0.6), 0px 1px 1px rgba(192, 192, 192, 0.6)",
                            }}>
                {t('main.howItWork.title')}
            </Typography>
            {/* </motion.div> */}
            </Divider>
            <Box sx={{
                display: "flex",
                flexDirection: {xs: 'column-reverse', md: 'row-reverse'},
                // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
            }}>
                {/*First block  */}
                <Box sx={{gap: '25px', padding: '7px'}}>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <Typography fontSize={24} fontWeight="600" align="center" variant="h5"
                                    sx={{margin: "20px 0", padding: '10px', color: '#32325d'}}>
                            {t('main.howItWork.leftBlock.title')}
                        </Typography>
                        <Typography color="#6B7280" fontSize={16} variant="subtitle1" component="p" padding={2}>
                            {t('main.howItWork.leftBlock.description')}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {xs: 'column', md: 'column'},
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: 'column', md: 'row'},
                        }}>
                            <Box sx={blockItemStyleLeft}>
                                <AppRegistrationIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.common.register')}
                                </Typography>
                            </Box>

                            <Box sx={blockItemStyleRight}>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.common.paymentMethod')}
                                </Typography>
                                <MonetizationOnIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: 'column', md: 'row'},
                        }}>
                            <Box sx={blockItemStyleLeft}>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.leftBlock.searchPros')}
                                </Typography>
                                <PersonSearchIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                            </Box>
                            <Box sx={blockItemStyleRight}>
                                <HowToRegIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.leftBlock.bookPro')}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: 'column', md: 'row'},
                        }}>
                            <Box sx={blockItemStyleLeft}>
                                <TagFacesIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.leftBlock.receiveService')}
                                </Typography>
                            </Box>
                            <Box sx={blockItemStyleRight}>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.leftBlock.submitReview')}
                                </Typography>
                                <StarRateIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Divider orientation={matches ? 'horizontal' : 'vertical'} flexItem/>

                <Box sx={{padding: '7px'}}>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <Typography fontSize={24} fontWeight="600" align="center" variant="h5"
                                    sx={{margin: "20px 0", padding: '10px', color: '#32325d'}}>
                            {t('main.howItWork.rightBlock.title')}
                        </Typography>
                        <Typography color="#6B7280" fontSize={16} variant="subtitle1" component="p" padding={2}>
                            {t('main.howItWork.rightBlock.description')}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {xs: 'column', md: 'column'},
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: 'column', md: 'row'},
                        }}>
                            <Box sx={blockItemStyleLeft}>
                                <AppRegistrationIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.common.register')}
                                </Typography>
                            </Box>
                            <Box sx={blockItemStyleRight}>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.common.paymentMethod')}
                                </Typography>
                                <MonetizationOnIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: 'column', md: 'row'},
                        }}>
                            <Box sx={blockItemStyleLeft}>
                                <MoreTimeIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.rightBlock.setRates')}
                                </Typography>
                            </Box>
                            <Box sx={blockItemStyleRight}>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.rightBlock.setCalendar')}
                                </Typography>
                                <EventRepeatIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                            </Box>
                        </Box>


                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: 'column', md: 'row'},
                        }}>
                            <Box sx={blockItemStyleLeft}>
                                <MilitaryTechIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.rightBlock.provideClient')}
                                </Typography>
                            </Box>
                            <Box sx={blockItemStyleRight}>
                                <Typography
                                    fontSize={17}
                                    fontWeight="600"
                                    variant="h3"
                                    sx={{margin: " ", padding: '20px 20px', color: 'black'}}
                                >
                                    {t('main.howItWork.rightBlock.getPaid')}
                                </Typography>
                                <CreditScoreIcon sx={{marginRight: 3, color: '#FF7600', scale: '1.5'}}/>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};
