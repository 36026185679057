import {Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from 'react-i18next';
import ImageSlider from './ImageSlider';
import { motion } from "framer-motion"

function EasOfUse() {
    const {t, i18n} = useTranslation();
      
  return (
    <Grid container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: "#F9FAFB",
            alignItems: "center",
            flexDirection: {xs:'column', md:"row"},
            marginBottom: 0,
            }}
    >
        <Grid item md={6} sm={12} xs={12} m={5}>
        <Divider sx={{color:'#FF7600'}} role="presentation">
            <Typography
                sx={{
                    fontSize: 30,
                    fontWeight: 600,
                    padding: '20px',
                    color:'#32325d'
                }}
            >
                {t('easeOfUse.title')}
            </Typography>
        </Divider>
            <Typography
                sx={{
                    fontSize: 15,
                    color: "#6B7280",
                    padding: '20px',
                }}
            >
                {t('easeOfUse.description')}
            </Typography>
        </Grid>
        <Grid item xs={11} md={5} lg={5}
            sx={{
                width:'80%', // Adjust the width of the grid item containing the slider
                height: 'auto', // Adjust the height of the grid item containing the slider
                // justifyContent:'center',
                alignItems:'center',
                padding:'10px 0 20px 0',
                borderRadius:'10px',
                // marginLeft:'10px'
                margin:'auto'
            }}
        > 
         {/* <motion.div
            initial={{ scale: 1 }} 
            animate={{
                scale: [.5, 1],
                rotate: [0, 360],
            }}
            transition={{ duration: 2, ease: 'easeInOut', repeat: 1, repeatDelay: .7 }}
            style={{ transformOrigin: "center" }}
        > */}
            <motion.div
                initial={{
                    scale:.7
                }}
                whileInView={{
                    scale:.9
                }}
                transition={{
                    duration:.5
                }}
                viewport={{ once: true }}
            >
            <ImageSlider />
            </motion.div>
        {/* </motion.div> */}
        </Grid>
    </Grid>
  )
}

export default EasOfUse