import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { Rascoin } from "../../Rascoin/Rascoin";

function RascoinHome() {
    const { t, i18n } = useTranslation();

    return (
        <Grid container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                flexDirection: { xs: 'column', md: "row" },
                marginBottom: 0,
                padding: { xs: 2, sm: 3, md: 5 } // Add padding to the container for consistent spacing
            }}
        >
            <Grid item xs={12} md={6} p={2}
                sx={{
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.6)',
                    borderRadius: '10px',
                }}
            >
                {/* <Divider sx={{ color: '#FF7600' }} role="presentation"> */}
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        color: '#32325d',
                        padding: '20px',
                        background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                        borderRadius: '10px',
                    }}
                >
                    {t('Rascoin.title')}
                </Typography>
                {/* </Divider> */}
                {/* <Typography
                    sx={{
                        fontSize: { xs: 15, sm: 15, md: 15 },
                        color: "#6B7280",
                        marginTop: 1 // Add margin for spacing between elements
                    }}
                >
                    {t('Rascoin.description1')}
                </Typography> */}
                <Typography
                    sx={{
                        fontSize: { xs: 15, sm: 15, md: 15 },
                        color: "#6B7280",
                        marginTop: 1 // Add margin for spacing between elements
                    }}
                >
                    {t('Rascoin.description2')}
                </Typography>
            </Grid>

            <Grid item xs={11} md={5}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0 20px 0',
                    borderRadius: '10px',
                }}
            >
                <motion.div
                    initial={{
                        scale: .7
                    }}
                    whileInView={{
                        scale: 1
                    }}
                    viewport={{ once: true }}
                    transition={{
                        duration: .5
                    }}
                    style={{ width: '100%' }} // Ensure the motion div takes full width
                >
                    <Rascoin />
                </motion.div>
            </Grid>
            <Grid container
                sx={{
                    background: 'linear-gradient(to right, rgba(224, 224, 224, 1), rgba(224, 224, 224, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                    borderRadius: "10px",
                    padding: '20px',
                    transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography
                    variant="h5"
                    m={2}
                    sx={{
                        fontWeight: 600,
                        color: '#32325d',
                    }}
                >
                    {t('Rascoin.title2')}
                </Typography>
                <Typography
                    m={2}
                    sx={{
                        fontSize: { xs: 15, sm: 15, md: 15 },
                        color: "#6B7280",
                        marginTop: 1,// Add margin for spacing between elements
                    }}
                >
                    {t('Rascoin.description3')}
                </Typography>
                {/* <Typography
                variant="h5"
                m={2}
                sx={{
                    fontWeight: 600,
                    color: '#32325d',
                }}
            >
                {t('Rascoin.title3')}
            </Typography>
            <Typography
                m={2}
                sx={{
                    fontSize: { xs: 15, sm: 15, md: 15 },
                    color: "#6B7280",
                    marginTop: 1,// Add margin for spacing between elements
                }}
            >
                {t('Rascoin.description4')}
            </Typography> */}
            </Grid>
        </Grid>
    );
}

export default RascoinHome;
